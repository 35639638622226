<template>
  <div class="faq">
    <Container>
      <Card padding="large">
        <Margins>
          <CardHeader :header="$t('FAQ')" />
          <Accordion
            name="general"
            :items="questions"
            :hasAnchor="true"
            @itemClicked="itemClicked"
            class="margins__triple"
          />

          <template>
            <Separator size="large" />

            <h3>{{ $t('FAQ_LIST_2_TITLE') }}</h3>
            <Accordion
              name="product-insurance"
              :items="questions2"
              :hasAnchor="true"
              @itemClicked="itemClicked"
              class="margins__triple"
            />

            <Separator size="large" />

            <h3>{{ $t('FAQ_LIST_3_TITLE') }}</h3>
            <Accordion
              name="payment"
              :items="questions3"
              :hasAnchor="true"
              @itemClicked="itemClicked"
              class="margins__triple"
            />
            <Separator size="large" />

            <h3>{{ $t('FAQ_LIST_4_TITLE') }}</h3>
            <Accordion
              name="pause"
              :items="questions4"
              :hasAnchor="true"
              @itemClicked="itemClicked"
              class="margins__triple"
            />
          </template>
        </Margins>
      </Card>
    </Container>
  </div>
</template>
‌
<script>
import {
  Accordion,
  Card,
  CardHeader,
  Container,
  Margins,
  Separator,
} from '@/components';
import { getDynamicTranslation } from '@/utils';
import { constants } from '@/mixins';

export default {
  name: 'Faq',
  mixins: [constants],
  components: {
    Container,
    Card,
    CardHeader,
    Margins,
    Accordion,
    Separator,
  },
  mounted() {
    if (this.$route.hash) {
      this.$root.$emit(
        'bv::toggle::collapse',
        'accordion-' + this.$route.hash.replace('#', ''),
      );
    }
  },
  computed: {
    questions() {
      return getDynamicTranslation(this.$i18n, 'FAQ_LIST');
    },
    questions2() {
      return getDynamicTranslation(this.$i18n, 'FAQ_LIST_2');
    },
    questions3() {
      return getDynamicTranslation(this.$i18n, 'FAQ_LIST_3');
    },
    questions4() {
      return getDynamicTranslation(this.$i18n, 'FAQ_LIST_4');
    },
  },
  methods: {
    itemClicked(event, itemId) {
      event.preventDefault();
      window.history.replaceState('', '', '#' + itemId);
    },
  },
};
</script>
